<template>
  <div class="dao-page">
    <div class="flex items-center justify-between p-24px">
      <div class="text-24px font-semibold">DAO</div>
      <div class="rounded-full px-12px  bg-[#fff] bg-opacity-20 flex items-center h-32px">
        <span class="text-14px">{{$t('head.aa1')}}</span>
        <!-- <img class="w-14px h-14px ml-6px" src="@/assets/new/arrow-right@2x (1).png" alt="" srcset=""> -->
      </div>
    </div>
    <div class="px-24px mt-24px grid grid-cols-2 gap-x-20px">
      <div @click="priceActive = 1" :class="priceActive == 1?'!opacity-100':''" class="bg-gradient-to-br from-[#3FF3B1] to-[#8A4CF4] px-16px py-24px rounded-30px opacity-80">
        <img class="w-34px h-34px " src="@/assets/new/Frame@2x.png" alt="" srcset="">
        <div class="mt-12px text-14px opacity-70">{{$t('head.vip1')}}</div>
        <div class="flex justify-between mt-6px items-center">
          <div class="leading-28px text-24px font-semibold">300U</div>
          <img class="w-18px h-18px" src="@/assets/new/arrow-right@2x (1).png" alt="" srcset="">
        </div>
      </div>
      <div @click="priceActive = 2" :class="priceActive == 2?'!opacity-100':''" class="bg-gradient-to-br from-[#FF5C0E] to-[#EE27E3] px-16px py-24px rounded-30px opacity-80">
        <img class="w-34px h-34px" src="@/assets/new/Frame@2x (1).png" alt="" srcset="">
        <div class="mt-12px text-14px opacity-70">{{$t('head.vip3')}}</div>
        <div class="flex justify-between mt-6px items-center">
          <div class="leading-28px text-24px font-semibold">2000U</div>
          <img class="w-18px h-18px" src="@/assets/new/arrow-right@2x (1).png" alt="" srcset="">
        </div>
      </div>
    </div>

    <div class="px-24px mt-20px">
      <div v-for="(item, index) in list" :key="index">
        <ItemComp
          class="mb-16px"
          v-if="item.block && item.show == true"
          :index="index"
          :data="item"
          @stake="onStake"
          @approve="onApprove"
        ></ItemComp>
      </div>
    </div>
  </div>
</template>
<script>
import ItemComp from '@/views/new/home/components/itemComp.vue'
import mixin from '@/mixins/index'
import { BigNumber } from 'bignumber.js'
export default {
  name: 'Dao',
  mixins: [mixin],
  components: {
    ItemComp,
  },
  data() {
    return {
      list:[],
      priceActive: 1,
      priceList: [
        {
          value: 1,
          balance: 300,
          lable: '300U',
        },
        {
          value: 2,
          balance: 2000,
          lable: '2000U',
        },
      ],
    }
  },
  computed: {
    currentPrice() {
      return this.priceList.find((e) => e.value == this.priceActive)
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.$axios.get(this.listdata + '/pool/list').then((res) => {
        res.data.data.map((item) => {
          let list = {}
          list.tokenaddress = item.token0
          list.tokenaddress1 = item.token1
          list.img1 = item.icon0
          list.img2 = item.icon1
          
          if (item.name0 == item.name1) {
            list.title = item.name0
          } else {
            list.title = item.name0 + '-' + item.name1
          }
          list.pid = item.pid
          list.deposit = item.name0 + '-' + item.name1
          list.earn = 'UNC'
          list.tokenname = item.name0
          list.tokenname1 = item.name1
          list.suanli = item.maxWeight + '%'
          list.block = true
          list.show = item.showp
          list.decimals0 = item.decimals0
          list.decimals1 = item.decimals1
          this.list.push(list)
        })
      })
    },
    onStake(e) {
      this.POOL5_contract.methods.deposit(e.pid, this.currentPrice.value).send({
          from: this.global.account,
        }).then(() => {
          this.$toast('SUCCESS')
        })
        .catch((err) => {
          this.$toast(err)
        })
    },
    onApprove(e) {
      const price = (this.currentPrice.balance * 10 * 1e18).toFixed(0)
      const Contract = new this.global.web3.eth.Contract(
        this.poolabi.erc20abi,
        e.tokenaddress
      )
      Contract.methods.approve(
          this.pool.pool5,
          this.global.web3.utils.numberToHex(BigNumber(price))
        ).send({
          from: this.global.account,
        }).then(() => {
          this.$toast('APPROVE SUCCESS')
        }).catch(() => {
          this.$toast('APPROVE ERROR')
        })
    },
  },
  watch: {
    'global.account'() {
      this.POOL5_contract = new this.global.web3.eth.Contract(
        this.poolabi.pool_abi5,
        this.pool.pool5
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.dao-page {
  background-image: url('~@/assets/new/dao-bg.png');
  background-repeat: no-repeat;
  background-size: 775px 775px;
  min-height: 100%;
  background-attachment: fixed;
}
</style>